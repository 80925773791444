import axios from 'axios';
import URLs from "../constants/URLs";

const opportunitiesApi = axios.create({
    // baseURL : URLs.api_base_URL,
    baseURL : URLs.api_v2_base_URL,
    // baseURL : URLs.api_v3_base_URL,
    headers:{
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
        // 'Content-Type': 'application/x-www-form-urlencoded'
    }
});

const filtersApi = axios.create({
    baseURL : URLs.api_base_URL,
    headers:{
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
        // 'Content-Type': 'application/x-www-form-urlencoded'
    }
});

export {
    opportunitiesApi,
    filtersApi,
};
