import React, {Suspense, lazy} from 'react';
import {Switch, withRouter, HashRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {renderRoutes} from "react-router-config";

import URLs from "./constants/URLs";
import RequireAuth from './components/RequireAuth/require_auth';
import Loading from "./components/UiKits/Loading/Loading";
import { Notifications } from 'react-push-notification';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
} from 'react-router-dom';
const My404Component = lazy(() => import('./components/ErrorBoundary/Page404'));
const SplashComponent = lazy(() => import('./components/SplashCom/SplashComponent'));
//Auth
const UserLoginComponent = lazy(() => import('./components/UserAuth/Login/UserLoginCom'));
const UserSignUpComponent = lazy(() => import('./components/UserAuth/SignUp/UserSignUpCom'));
const UserLogoutComponent = lazy(() => import('./components/UserAuth/Logout/UserLogoutCom'));
const UserVerifyComponent = lazy(() => import('./components/UserAuth/VerifyAccount/UserVerifyAccount'));
const UserResetPasswordComponent = lazy(() => import('./components/UserAuth/ResetPassword/UserResetPasswordCom'));
const UserForgotPassword = lazy(() => import('./components/UserAuth/ForgotPassword/UserForgotPassword'));
//MemberShip
const MemberShipPackages = lazy(() => import('./components/MemberShip/PackagesCom/PackagesCom'));
const PurchaseComponent = lazy(() => import('./components/MemberShip/PurchaseCom/PurchaseCom'));
const AuthComponent = lazy(() => import('./components/UserAuth/AuthComponent'));
//Dashboard
const DashboardMainCom = lazy(() => import('./components/UserDashboard/Main/DashboardMainCom'));
const DashboardOpportunitiesCom = lazy(() => import('./components/UserDashboard/Posts/OpportunitiesCom'));
const DashboardOpportunitySingle = lazy(() => import('./components/UserDashboard/Posts/OpportunitySingle'));
const DashboardCreateOpportunityCom = lazy(() => import('./components/UserDashboard/Posts/CreateOpportunity/CreateOpportunityCom'));
const EditProfileCom = lazy(() => import('./components/UserDashboard/Settings/EditProfile/EditProfileCom'));
const PostsCom = lazy(() => import('./components/UserDashboard/Articles/PostsCom'));
const PostSingle = lazy(() => import('./components/UserDashboard/Articles/PostSingle'));
const PostsReactions = lazy(() => import('./components/UserDashboard/Articles/Reactions/PostsReactions'));
const CreatePost = lazy(() => import('./components/UserDashboard/Articles/Create/CreatePost'));
const ProfileCom = lazy(() => import('./components/UserDashboard/Settings/ShowProfile/ProfileCom'));
const MessagesCom = lazy(() => import('./components/UserDashboard/Messages/MessagesCom'));
const CompanyProfileCom = lazy(() => import('./components/UserDashboard/Company/CompanyProfileCom'));
const CreateCompany = lazy(() => import('./components/UserDashboard/Company/Create/CreateCompany'));
const EditCompanyCom = lazy(() => import('./components/UserDashboard/Company/Edit/EditCompany'));
const SearchMembers = lazy(() => import('./components/UserDashboard/SearchMembers/SearchMembers'));
// const ExampleComponent = lazy(() => import('./components/ExampleCom'));

const MainRoot = ({route}) => (
    <>
      <Notifications />
      {renderRoutes(route.routes)}
    </>
  )
;


const routes = [
  {
    component: MainRoot,
    routes: [
      {
        path: "/",
        exact: true,
        component: SplashComponent
      },
      {
        path: "/auth/opportunity/:kind/:id",
        component: DashboardOpportunitySingle,
      },
      {
        path: URLs.auth,
        component: RequireAuth(AuthComponent, 'auth'),
        routes: [
          {
            path: URLs.login,
            component: UserLoginComponent,
          },
          {
            path: "/auth/register/:package?/:id?",
            component: UserSignUpComponent,
          },
          {
            path: URLs.verify,
            component: UserVerifyComponent,
          },
          {
            path: URLs.forgetPassword,
            component: UserForgotPassword,
          },
          {
            path: URLs.resetPassword,
            component: UserResetPasswordComponent,
          },
          
        ]
      },
      {
        path: URLs.membership,
        // component: RequireAuth(AuthComponent, 'force_membership'),
        component: RequireAuth(AuthComponent, 'force_membership'),
        routes: [
          {
            path: URLs.packages,
            component: MemberShipPackages,
          },
          {
            path: '/membership/pay/:id',
            component: PurchaseComponent,
          },
        ]
      },
      {
        path: URLs.dashboard,
        component: RequireAuth(DashboardMainCom, 'force_dashboard'),
        routes: [
          {
            path: '/dashboard/opportunities/:kind?',
            component: DashboardOpportunitiesCom,
          },
          {
            path: URLs.dashboard_opportunities_create,
            component: DashboardCreateOpportunityCom,
          },
          {
            path: "/dashboard/opportunity/:kind/:id",
            component: DashboardOpportunitySingle,
          },
          {
            path: URLs.dashboard_user_edit_profile,
            component: EditProfileCom,
          },
          {
            path: '/dashboard/posts/:kind?',
            component: PostsCom,
          },
          {
            path: "/dashboard/post/:kind/:id",
            component: PostSingle,
          },
          {
            path: "/dashboard/post-reactions/:id",
            component: PostsReactions,
          },
          {
            path: URLs.dashboard_posts_create,
            component: CreatePost,
          },
          {
            path: URLs.dashboard_user_profile,
            component: ProfileCom,
          },
          {
            path: "/dashboard/messages",
            component: MessagesCom,
          },
          {
            path: "/dashboard/company/profile/:id/:search?",
            component: CompanyProfileCom,
          },
          {
            path: URLs.dashboard_create_company,
            component: CreateCompany,
          },
          {
            path: URLs.dashboard_company_update,
            component: EditCompanyCom,
          },
          {
            path: URLs.dashboard_search_members,
            component: SearchMembers,
          },
          {
            component: My404Component
          },
        ]
      },
      {
        path: URLs.logout,
        component: UserLogoutComponent
      },
      {
        component: My404Component
      },
    ]

  }
];


const RouteAPI = () => (
  <HashRouter>
    <Suspense fallback={<Loading/>}>
      <Switch>
        {/*<Route path={URLs.pay + "/:id"} component={RequireAuth(PurchaseComponent, 'force')}/>*/}
        {/*<Route path={URLs.logout} component={UserLogoutComponent}/>*/}

        {renderRoutes(routes)}

        {/*<Route component={My404Component}/>*/}
      </Switch>
    </Suspense>
  </HashRouter>
);

const mapStateToProps = state => {
  return {
    // isAuthenticated: state.auth.token !== null,
  };
};

export default withRouter(connect(mapStateToProps, null)(RouteAPI));
