import * as actionTypes from './../../constants/actionTypes';
import {publicApi} from "../../api/public";
import URLs from "../../constants/URLs";
import {setLoading} from "./posts";

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    };
};

export const updateCompanyInfo = (payload) => {
    return {
        type: actionTypes.UPDATE_COMPANY,
        payload
    };
};

export const authSuccess = (token, userData, userCompany,userType ) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        token: token,
        userData: userData,
        userCompany: userCompany,
        userType : userType

    };
};

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    };
};

export const sendlogoutToReducer = () => {
    return {
        type: actionTypes.AUTH_LOGOUT
    };
};

export const updateUserInfo = (token) => {
    return dispatch =>{
        let params = {
            user_id: token,
            user_id_find: token //me
        };
        params = {'0': JSON.stringify(params)};

        publicApi.post(URLs.api_base_URL + URLs.api_getUserInfo, params)
          .then(res => {
              // console.log(res);
              if(res.data.isSuccess){
                  publicApi.post(URLs.api_v3_base_URL + URLs.api_getUserType+res.data.data.user_id).then((res)=>{
                      dispatch(authSuccess(res.data.data));
                  })
                  localStorage.setItem('userData', JSON.stringify(res.data.data));
                  localStorage.setItem('token', res.data.data.user_id);
                  let userCompany = JSON.parse(localStorage.getItem('userCompany'));
                  dispatch(authSuccess(res.data.data.user_id, res.data.data, userCompany));
              }
          })
          .catch(err => {
              // console.log(err);
              return false;
          })
    }
}

export const getCompanyDetails = (companyId, token) => {
    return dispatch =>{
        dispatch(setLoading({error: null, loading: true, redirect: null}))
        let params = {
            user_id: token,
            access: 'full',
            company_id: companyId
        }
        params = {'0': JSON.stringify(params)};
        //send api

        publicApi.post(URLs.api_base_URL + "/" + URLs.api_getCompany, params)
          .then(res => {
              // console.log(res);
              if (res.data.isSuccess) {
                  localStorage.setItem('userCompany', JSON.stringify(res.data.data));

                  dispatch(updateCompanyInfo(res.data.data))
              } else {
                  //show error
                  dispatch(authFail('We have Error'))
              }

              dispatch(setLoading({error: null, loading: false, redirect: null}))
          })
          .catch(err => {
              //show error
              dispatch(authFail('We have Error'))
              console.log(err);
          })


    }
}
