import * as actionTypes from '../../constants/actionTypes';
import { updateObject } from '../utility';
const initialState = {
    error: null,
    loading: false,
    redirect: false,
    searchMessage: []
};

const setLoading = ( state, action ) => {
    return updateObject( state, {
        error: action.payload.error || null,
        loading: action.payload.loading,
        redirect: action.payload.redirect || null,
    });
};

const setError = (state, action) => {
    return updateObject( state, {
        error: action.payload.error,
        loading: action.payload.loading || null,
        redirect: action.payload.redirect || null,
    } );
};

const setMessage = (state, action) => {
    return updateObject( state, {
        searchMessage: action.payload.searchMessage
    } );
};

const globalReducers = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.SET_LOADING: return setLoading(state, action);
        case actionTypes.SET_ERROR: return setError(state, action);
        case actionTypes.SET_MESSAGE: return setMessage(state, action);
        default:
            return state;
    }
};

export default globalReducers;
