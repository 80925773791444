export const  AUTH_START = 'AUTH_START';
export const  AUTH_SUCCESS = 'AUTH_SUCCESS';
export const  AUTH_FAIL = 'AUTH_FAIL';
export const  AUTH_LOGOUT = 'AUTH_LOGOUT';
//posts
export const  SET_POSTS = 'SET_POSTS';
//Opportunities
export const  SET_OPPORTUNITIES = 'SET_OPPORTUNITIES';
//messages
export const  SET_MESSAGE_CONTACTS = 'SET_MESSAGE_CONTACTS';
export const  SET_CHAT_HISTORY = 'SET_CHAT_HISTORY';
export const  SET_STATE_CHAT_HISTORY = 'SET_STATE_CHAT_HISTORY';
export const  NEW_CONVERSATION = 'NEW_CONVERSATION';
export const  SEARCH_CONVERSATION = 'SEARCH_CONVERSATION';
export const  SET_NOTIFICATION = 'SET_NOTIFICATION';
//global
export const  SET_LOADING = 'SET_LOADING';
export const  SET_ERROR = 'SET_ERROR';
export const  SET_SEARCH = 'SET_SEARCH';
export const  SET_MESSAGE = 'SET_MESSAGE';
export const  SET_SEARCH_MEMBERS = 'SET_SEARCH_MEMBERS';
//COMPANY
export const  UPDATE_COMPANY = 'UPDATE_COMPANY';

