import axios from 'axios';

const publicApi = axios.create({
  baseURL: '',
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
    // 'Content-Type': 'application/x-www-form-urlencoded'
  }
});



export {
  publicApi,
};
