import * as actionTypes from '../../constants/actionTypes';
import {updateObject} from '../utility';

const initialState = {
  items: [],
  count: 0,
  pages: 0,
  newCount: 0,
  search: ""
};


const setPosts = (state = initialState, action) => {
  if (action.payload.resetData) {
    return updateObject(state, {
      items: action.payload.posts,
      count: action.payload.posts.length,
      pages: action.payload.pages,
      newCount: action.payload.newCount,
      search: action.payload.search || ""
    });
  } else {
    action.payload.posts.map(item => state.items.push(item))
    state.count = action.payload.posts.length;
    state.pages = action.payload.pages;
    state.newCount = action.payload.newCount;
    state.search = action.payload.search || ""
    return updateObject(state, state);
  }
};

const setSearch = (state = initialState, action) => {
  state.search = action.payload;
  return updateObject(state, state);
}


const postsReducers = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_POSTS:
      return setPosts(state, action);
    case actionTypes.SET_SEARCH:
      return setSearch(state, action);
    default:
      return state;
  }
};

export default postsReducers;
