import * as actionTypes from '../../constants/actionTypes';
import {updateObject} from '../utility';

const initialState = {
  items: [],
  count: 0,
  pages: 0,
  newCount: 0
};


const setOpportunities = (state, action) => {
  if (action.payload.resetData) {
    return updateObject(state, {
      items: action.payload.opportunities,
      count: action.payload.opportunities.length,
      pages: action.payload.pages,
      newCount: action.payload.newCount
    });
  }else {
    action.payload.opportunities.map(item=>state.items.push(item))
    state.count = action.payload.opportunities.length;
    state.pages = action.payload.pages;
    state.newCount = action.payload.newCount;
    return updateObject(state, state);
  }


};


const opportunitiesReducers = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_OPPORTUNITIES:
      return setOpportunities(state, action);
    default:
      return state;
  }
};

export default opportunitiesReducers;
