import * as actionTypes from '../../constants/actionTypes';
import { updateObject } from '../utility';
const initialState = {
    token: null,
    userData: null,
    userCompany: null,
    error: null,
    loading: false,
    redirect: false,
};

const authStart = ( state, action ) => {
    return updateObject( state, { error: null, loading: true } );
};

const authSuccess = (state, action) => {
    return updateObject( state, {
        token: action.token,
        userData: action.userData,
        userCompany: action.userCompany,
        userType : action.userType,
        error: null,
        loading: false,
    } );
};

const authFail = (state, action) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    });
};

const authLogout = (state, action) => {
    return updateObject(state, { token: null, userData: null, userCompany: null });
};

const updateCompanyInfo = (state, action) => {
    return updateObject( state, {
        userCompany: action.payload,
        error: null,
        loading: false,
    } );
};

const authReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.AUTH_START: return authStart(state, action);
        case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
        case actionTypes.UPDATE_COMPANY: return updateCompanyInfo(state, action);
        case actionTypes.AUTH_FAIL: return authFail(state, action);
        case actionTypes.AUTH_LOGOUT: return authLogout(state, action);
        default:
            return state;
    }
};

export default authReducer;
