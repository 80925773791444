import React from 'react';
import './assets/css/main.css';
import './assets/plugins/bootstrap/bootstrap.min.css';
import ReactDOM from 'react-dom';
import reducers from './store/reducers/index';
import {Provider} from 'react-redux';
import * as serviceWorker from './serviceWorker';
import {
  createStore
  , applyMiddleware
  , compose
} from 'redux';
import thunk from "redux-thunk";
import {createLogger} from 'redux-logger';
import RouteAPI from "./RouterAPI";
import {createBrowserHistory} from 'history';
import {Router} from 'react-router';
import JavascriptTimeAgo from 'javascript-time-ago';
import TimeAgo from 'javascript-time-ago'
import {BrowserRouter} from "react-router-dom";
// import {composeWithDevTools} from 'redux-devtools-extension';
// import * as actions from './redux/actions/index';
import en from 'javascript-time-ago/locale/en';
TimeAgo.addDefaultLocale(en)


JavascriptTimeAgo.addLocale(en);

const middleware = [];
if (process.env.NODE_ENV !== 'production') {
  middleware.push(createLogger());
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(reducers
  // development
  // , composeWithDevTools(applyMiddleware(...middleware,thunk))
  , composeEnhancers(applyMiddleware(...middleware, thunk))
  // production
  // , composeEnhancers(applyMiddleware(thunk))
);


ReactDOM.render(
  <Provider store={store}>
    {/*<Router history={createBrowserHistory()}>*/}
    <BrowserRouter>
      <RouteAPI/>
    </BrowserRouter>
  </Provider>
  ,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
