import * as actionTypes from '../../constants/actionTypes';
import {updateObject} from '../utility';

const initialState = {
  allData: [],
  chatHistory: {
    extra: [],
    messages: []
  },
  loadChatHistory: false,
  errorChatHistory: false,
  activeMessageId: 0,
  newConversation: false,
  searchResults: null,
  notifications: 0
};

const setNotification = (state, action) => {
  return {
    ...state,
    notifications: action.payload
  }
}

const setSearchResult = (state, action) => {
  let searchResults = [];

  if (action.payload !== "") {
    state.allData.map(item =>
      ((item.name).toLowerCase().includes(action.payload.toLowerCase()) || (item.lastname).toLowerCase().includes(action.payload.toLowerCase())) &&
      searchResults.push(item)
    )
  } else {
    searchResults = null;
  }

  return {
    ...state,
    searchResults: searchResults
  }
}

const setMessages = (state, action) => {
  return updateObject(state, action.payload)
}

const newConversation = (state, action) => {
  return {
    ...state,
    newConversation: action.payload
  }
}

const setChatHistory = (state, action) => {
  if (action.payload.resetData) {
    return updateObject(state, action.payload)
  } else {

    if (state.chatHistory.messages.length !== 0) {
      let newMessages = [];
      for (let i = 0; i <= action.payload.messages.length - 1; i++) {

        if (action.payload.messages[i]._id !==
          state.chatHistory.messages[state.chatHistory.messages.length - 1]._id) {
          newMessages.push(action.payload.messages[i])
        }
      }

      return {
        ...state,
        chatHistory: {
          extra: state.chatHistory.extra,
          messages: [...state.chatHistory.messages, ...newMessages]
        },
      }
    } else {
      return {
        ...state
      }
    }
  }
}

const setStateChatHistory = (state, action) => {
  return updateObject(state, action.payload)
}

const messagesReducers = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_MESSAGE_CONTACTS:
      return setMessages(state, action);
    case actionTypes.SET_CHAT_HISTORY:
      return setChatHistory(state, action);
    case actionTypes.SET_STATE_CHAT_HISTORY:
      return setStateChatHistory(state, action);
    case actionTypes.NEW_CONVERSATION:
      return newConversation(state, action);
    case actionTypes.SEARCH_CONVERSATION:
      return setSearchResult(state, action);
    case actionTypes.SET_NOTIFICATION:
      return setNotification(state, action);
    default:
      return state;
  }
};

export default messagesReducers;
