import * as actionTypes from './../../constants/actionTypes';
import {publicApi} from "../../api/public";
import URLs from "../../constants/URLs";
import {setError, setLoading} from "./posts";

export const setNotification = payload => {
  return {
    type: actionTypes.SET_NOTIFICATION,
    payload
  }
}
export const searchConversation = payload => {
  return {
    type: actionTypes.SEARCH_CONVERSATION,
    payload
  }
}

export const newConversation = payload => {
  return {
    type: actionTypes.NEW_CONVERSATION,
    payload
  }
}

export const setMessages = payload => {
  return {
    type: actionTypes.SET_MESSAGE_CONTACTS,
    payload
  };
}

export const setStateChatHistory = payload => {
  return {
    type: actionTypes.SET_STATE_CHAT_HISTORY,
    payload
  }
}

export const setChatHistory = payload => {
  return {
    type: actionTypes.SET_CHAT_HISTORY,
    payload
  }
}

export const fetchMessages = (token) => {
  return dispatch => {
    dispatch(setLoading({loading: true}))
    let params = {
      user_id: token,
    };
    params = {'0': JSON.stringify(params)};

    publicApi.post(URLs.api_base_URL + URLs.api_messageConvListGet, params)
      .then(res => {
        // console.log(res)
        if (res.data.isSuccess) {
          dispatch(setMessages({
            allData: res.data.data.reverse(),
          }))
          // dispatch to store and pass to lists component
          dispatch(setLoading({loading: false}))
        } else {
          //set error
          dispatch(setError({error: 'Error', loading: false, redirect: null}))
        }
      })
      .catch(err => {
        dispatch(setError({error: 'Error', loading: false, redirect: null}))
        // console.log(err)
      })
  }
}

export const fetchChatHistory = (id) => {
  return dispatch => {
    dispatch(setLoading({loading: true}))
    dispatch(setStateChatHistory({
      loadChatHistory: true,
      errorChatHistory:null,
      chatHistory: {extra:[], messages: []},
    }))

    publicApi.get(URLs.realTimeDataBase+`conversation/${id}.json`)
      .then(res => {
        // console.log( 'fetchChatHistory',res)
        if (res.status === 200) {
          // dispatch to store and pass to chat room component
          if(res.data !== null ) {
            let msg = Object
              .entries(res.data.messages)
              .map(([key , value]) =>
                {
                  return {
                    ...value,
                    key
                  }
                }
              );
            let extra = Object
              .entries(res.data.extra)
              .map(([key , value]) =>
                {
                  return {
                    ...value,
                    key
                  }
                }
              );
            dispatch(setChatHistory({
              chatHistory: {extra:extra, messages: msg},
              resetData: true,
              activeMessageId: id,
              newConversation: false
            }))
            dispatch(setStateChatHistory({loadChatHistory: false}))
            dispatch(setLoading({loading: false}))
          }else {
            //there is no conversation, create conversation mode
            dispatch(newConversation(true))
          }
        } else {
          //set error
          dispatch(setStateChatHistory({loadChatHistory: false, errorChatHistory:'Error'}))
          dispatch(setLoading({loading: false}))
        }
      })
      .catch(err => {
        dispatch(setStateChatHistory({loadChatHistory: false, errorChatHistory:'Error'}))
        console.log(err)
      })
  }
}

// let jsonHandler = (data) => {
//   return dispatch => {
//     let msg = Object
//       .entries(data.messages)
//       .map(([key , value]) =>
//         {
//           return {
//             ...value,
//             key
//           }
//         }
//       );
//     dispatch(setChatHistory({chatHistory: {extra:data.extra, messages: msg}}))
//   }
// }

export const fetchChatHistoryFromCoimexServer = (user_id, user_id_to) => {
  return dispatch => {
    dispatch(setStateChatHistory({loadChatHistory: true, errorChatHistory:null}))
    let params = {
      user_id,
      user_id_to
    };
    params = {'0': JSON.stringify(params)};

    publicApi.post(URLs.api_base_URL + URLs.api_messageConvMsgGet, params)
      .then(res => {
        // console.log(res)
        if (res.data.isSuccess) {
          // dispatch to store and pass to chat room component
          dispatch(setChatHistory({chatHistory: res.data.data}))
          dispatch(setStateChatHistory({loadChatHistory: false}))
        } else {
          //set error
          dispatch(setStateChatHistory({loadChatHistory: false, errorChatHistory:'Error'}))
        }
      })
      .catch(err => {
        dispatch(setStateChatHistory({loadChatHistory: false}))
        console.log(err)
      })
  }
}

