import {toast} from "react-toastify";
import thumbnail from "../assets/img/no-thumbnail.png";
import sampleAvatarImg from "../assets/img/avatar.png";
import companyImage from "../assets/img/svg/company.svg";

// params = {'0': JSON.stringify(params)};

export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

export const readDollar = (num) => {
    let dollars = num / 100;
    return dollars.toLocaleString("en-US", {style:"currency", currency:"USD"});
};

export const isArray = (a) => {
    return (!!a) && (a.constructor === Array);
};

export const isObject = (a) =>  {
    return (!!a) && (a.constructor === Object);
};

export const showError = (data, autoClose) => {
    let text, timeOut;
    data ? text = data : text =`Error, try again`;
    autoClose ? timeOut = autoClose : timeOut = 4000;

    return toast.error(text,{
        position: "bottom-right",
        autoClose: timeOut,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    })
}

export const showSuccess = (data, autoClose) => {
    let text, timeOut;
    data ? text = data : text =`Success!`;
    autoClose ? timeOut = autoClose : timeOut = 4000;

    return toast.success(text,{
        position: "bottom-right",
        autoClose: timeOut,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    })
}

export const uploadToast = (data) => {
    let text;
    data ? text = data : text =`Success!`;

    return toast.success(text,{
        position: "bottom-right",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    })
}

export const handleThumbError = (e) => {
    e.target.src = thumbnail;
}

export const handleCompanyImageError = (e) => {
    e.target.src = companyImage;
}

export const handleAvatarError = (e) => {
    e.target.src = sampleAvatarImg;
}



//TODO:: urlify

// export const  URLify = (string) =>{
//     const urls = string.match(/(((ftp|https?):\/\/)[\-\w@:%_\+.~#?,&\/\/=]+)/g);
//     if (urls) {
//         urls.forEach(function (url) {
//             string = string.replace(url, '<a target="_blank" href="' + url + '" rel="noreferrer noopener">' + url + "</a>");
//         });
//     }
//     return string.replace("(", "<br/>(");
// }
