import React, {Component} from 'react';
import {connect} from 'react-redux';
import {authSuccess} from "../../store/actions";

export default function (ComposedComponent, url) {
  class Authentication extends Component {
    componentDidMount() {
      this._isMounted = true;
      if (this._isMounted) {
        let userData = JSON.parse(localStorage.getItem('userData'));
        let token = localStorage.getItem('token');
        let userCompany = JSON.parse(localStorage.getItem('userCompany'));
        let userType = JSON.parse(localStorage.getItem('userType'));

        if ((userData) && (url === 'force_membership' || url === 'force_dashboard')) {
          // old api
          // if (this.props.isAuthenticated) this.props.authSuccess(userData.user_id, userData);
          if (this.props.isAuthenticated) this.props.authSuccess(token, userData, userCompany, userType);
            // if (userType.current_membership.name ==="Starter"||"Scale"||"Growth" && url === 'force_membership') {
            if (userData.profile_type === 'Master' && url === 'force_membership') {
             if(this.props.location.pathname === "/membership/package"){
               this.props.history.push('/membership/packages');
             }else{
               this.props.history.push('/dashboard/profile/' + token);
             }
              // } else if (userType.current_membership.name === 'Freemium' && url === 'force_dashboard') {
            } else if (userData.profile_type === 'Free' && url === 'free_membership') {
              this.props.history.push('/membership/packages');
          }
        }
        if ((userData === null && url === 'force')) {
          localStorage.clear();
          this.props.history.push('/auth/login');
        }
        if ((userData !== null && url === 'member')) {
          // old api
          // if (this.props.isAuthenticated) this.props.authSuccess(userData.user_id, userData);
          if (this.props.isAuthenticated) this.props.authSuccess(userData._id, userData);
          this.props.history.push('/membership/packages');
        }
        if ((userData !== null && url === 'auth')) {
          // old api
          // if (this.props.isAuthenticated) this.props.authSuccess(userData.user_id, userData);
          if (this.props.isAuthenticated) this.props.authSuccess(userData._id, userData);
          this.props.history.push('/membership/packages');
        }

        userData = null;
      }
    }

    componentWillUnmount() {
      this._isMounted = false;
    }

    componentDidUpdate(nextProps) {
      if (!nextProps.isAuthenticated) {
        // this.props.history.push('/auth/login');
      }
    }

    render() {
      return <ComposedComponent {...this.props} />;
    }
  }

  const mapStateToProps = state => {
    return {
      isAuthenticated: state.auth.token === null,
    };
  };

  const mapDispatchToProps = (dispatch) => {
    return {
      authSuccess: (token, userData, userCompany, userType) => dispatch(authSuccess(token, userData, userCompany, userType)),
    };
  };

  return connect(mapStateToProps, mapDispatchToProps)(Authentication);
}
