export default {

  app_site_URL: 'https://app.coimex.co',
  base_URL:'https://coimex.xyz',
  api_base_URL:'https://coimex.xyz/ios/',
  api_v2_base_URL:'https://coimex.xyz/api/v2',
  api_v3_base_URL:'https://coimex.xyz/api/v3',

   //
  //  base_URL:'https://coimex.biz',
  //  api_base_URL:'https://coimex.biz/ios/',
  //  api_v2_base_URL:'https://coimex.biz/api/v2',
  //  api_v3_base_URL:'https://coimex.biz/api/v3',



  // base_URL: 'http://130.185.123.171:4000', // test ip(localhost)
  // api_base_URL: 'http://130.185.123.171:4000/ios/', // test ip (localhost)
  // api_v2_base_URL: 'http://130.185.123.171:4000/api/v2', // test ip v2(localhost)
  // api_v3_base_URL: 'http://130.185.123.171:4000/api/v3', // test ip v3(localhost)
  // base_URL:'https://urman-test.ir', // test ssl ip
  // api_base_URL:'https://urman-test.ir/ios/', // test ssl ip
  // api_v2_base_URL:'https://urman-test.ir/api/v2', // test ssl ip v2
  // api_v3_base_URL:'https://urman-test.ir/api/v3', // test ssl ip v3
  app_login: 'https://app.coimex.co/#/login',
  app_signUp: 'https://app.coimex.co/#/register',
  StripeToken: 'pk_live_D8iqpQXq8p7XRdgq4o0WtuSc00Rugo3Jyd',
  // StripeToken : 'pk_test_6pRNASCoBOKtIshFeQd4XMUh',
  realTimeDataBase: 'https://coimex-utechia.firebaseio.com/',


  //******api'
  //===auth
  api_login: '/login',
  api_register: '/register',
  api_registerVerify: '/registerverify',
  api_forgotPassword: '/forgot-password',
  api_resetPassword: "/reset-password",
  api_getUserInfo: "getuserinfo",
  api_getUserType:"/user/check_user_restrictions?user_id=",
  //===membership
  api_getMemberShips: "/getmemberships-webapp",
  api_getmembershipById: "/getmembershipById",
  api_buy_membershippackage_with_stripe: "buy_membershippackage_with_stripe",
  api_promotionCalculate: "promotion-calculate",
  //===public data
  api_getFullCategories: "getfullcategories",
  api_getCountries: "getcountries",
  api_getLanguages: "getlanguages",
  api_getFullIntRegions: "getfullintregions",
  //===for opportunities
  api_addPost: "addpost",
  api_uploadPostPhotos: "uploadpostphotos",
  api_v3_uploadPostPhotos: "post/upload",
  api_uploadUserPhoto: "uploaduserphoto",
  api_updateUserInfo: "updateuserinfo",
  api_getPostInfo: "getpostinfo",
  api_removepost: "removepost",
  //===for posts (articles in api)
  api_getArticleLimited: "app/article-get-limited",
  api_removeReaction: "app/article-removereaction",
  api_addReaction: "app/article-addreaction",
  api_spamPost: "app/article-spam",
  api_rePost: "app/article-repost",
  api_sharePost: "app/article-share",
  api_postReactionsList: "app/article-reactionlist",
  api_createPost: "app/article-add",
  api_editPost: "app/article-edit",
  api_uploadPost: "app/article-upload",
  api_v3_uploadPost: "article/upload",
  api_getArticleInfo: "getarticleinfo",
  //===interactions
  api_unFollow: "app/unfollow",
  api_follow: "app/follow",
  api_followersList: "app/followerslist",
  api_followingsList: "app/followingslist",
  //===company
  api_getCompany: "getCompany",
  api_uploadCompany: "company/upload",
  api_addCompany: "addCompany",
  //===company v2
  api_uploadCompanyPhotos: "uploadcompanyphotos",
  api_editCompanyPhotos: "editcompanyphotos",
  api_removeCompanyPhoto: "removeCompanyPhoto",
  //====messages
  api_messageConvListGet: "messageconvlistget",
  api_messageConvMsgGet: "messageconvmsgget",
  api_sendMessage: "message/send",
  //===search
  api_multiSearch: "multi-search",


  //******views
  //*
  //===auth
  auth: "/auth",
  home: "/home",
  login: '/auth/login',
  logout: '/logout',
  register: "/auth/register",
  resetPassword: "/auth/resetPassword",
  verify: "/auth/verify",
  forgetPassword: '/auth/forgotPassword',
  //*
  //===memberShip
  membership: '/membership',
    packages: '/membership/packages',
  pay: '/membership/pay',
  //*
  //===dashboard
  dashboard: '/dashboard',
  //*
  dashboard_home: '/dashboard/home',
  //===dashboard opportunities
  dashboard_opportunities: '/dashboard/opportunities',
  dashboard_opportunities_create: "/dashboard/create-opportunity",
  dashboard_opportunity_details: "/dashboard/opportunity/details",
  dashboard_opportunity_edit: "/dashboard/opportunity/edit",
  get_post_limited: "/app/post-get-limited",
  //*
  //===dashboard posts
  dashboard_posts: '/dashboard/posts',
  dashboard_posts_create: "/dashboard/create-post",
  dashboard_posts_reactions: "/dashboard/post-reactions",
  dashboard_posts_details: "/dashboard/post/details",
  dashboard_post_edit: "/dashboard/post/edit",
  //*
  //===dashboard company
  dashboard_company_update: "/dashboard/company/update",
  dashboard_company_profile: "/dashboard/company/profile",
  dashboard_create_company: "/dashboard/company/create",
  //*
  //===dashboard user
  dashboard_user_profile: "/dashboard/profile/:id",
  dashboardUserProfile: "/dashboard/profile",
  dashboard_user_edit_profile: "/dashboard/edit-profile",
  dashboard_messages: "/dashboard/messages",
  dashboard_settings: "/dashboard/settings",
  //*
  //===search members
  dashboard_search_members: "/dashboard/search-members",

  //download links
  ios_download: 'https://apps.apple.com/us/app/coimex/id1502251256',
  android_download: 'https://play.google.com/store/apps/details?id=com.coimexu',

  socialMedia: {
    twitter: "https://twitter.com/CoimexGlobal",
    instagram: "https://www.instagram.com/coimexglobal/",
    facebook: "https://www.facebook.com/Coimex-Global-101801881550167",
    youtube: "https://www.youtube.com/channel/UCFJJt41XpMxw5eTxlpA3WRQ"
  },


  share: {
    twitter: "https://www.twitter.com/intent/tweet?url=",
    facebook: "http://www.facebook.com/share.php?u=",
    linkedIn: "https://www.linkedin.com/shareArticle?mini=true&url=",
    whatsApp: "https://wa.me/?text=",
    url: "https://app.coimex.co/#/dashboard/post/details/"
  }

};


