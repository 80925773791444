import * as actionTypes from './../../constants/actionTypes';
import URLs from "../../constants/URLs";
import {opportunitiesApi} from "../../api/opportunities";
import {publicApi} from "../../api/public";


export const setLoading = (payload) => {
  return {
    type: actionTypes.SET_LOADING,
    payload
  };
};

export const setOpportunities = (payload) => {
  return {
    type: actionTypes.SET_OPPORTUNITIES,
    payload
  };
};

export const setError = (payload) => {
  return {
    type: actionTypes.SET_ERROR,
    payload
  };
};

export const setMessage = (payload) => {
  return {
    type: actionTypes.SET_MESSAGE,
    payload
  };
};

export const fetchOpportunities = (data) => {
  return dispatch => {
    //clear some data on store
    if (data.resetData) {
      dispatch(setOpportunities({
        opportunities: [],
        newCount: 0,
        pages: 0,
        resetData: data.resetData
      }));
    }
    dispatch(setLoading({error: null, loading: true, redirect: null}));
    dispatch(setMessage({searchMessage: []}));

    // let slug = 'token=' + data.token +
    //   '&token_posts=' + data.token_posts + '&page_first=' + data.page_first +
    //   '&page=' + data.page + '&size=' + data.size +
    //   '&price_to=' + data.price_to + '&price_from=' + data.price_from +
    //   '&day_count=' + data.day_count +
    //   '&country_id=' + data.country_id + '&categorysub_id=' + data.categorysub_id +
    //   '&text=' + data.text;
    let slug = 'token=' + data.token +
      '&token_posts=' + data.token_posts + '&page_first=' + data.page_first +
      '&page=' + data.page + '&take=' + data.size +
      '&priceTo=' + data.price_to + '&priceFrom=' + data.price_from +
      '&dayCount=' + data.day_count +
      '&countryId=' + data.country_id + '&categorySubId=' + data.categorysub_id +
      '&text=' + data.text + "&target="+data.target+"&lastId="+data.page
    // take=10&search=&=&=&lastId=
    //axios request
    publicApi.get( URLs.api_v3_base_URL + "/post" + "/?" + slug)
      .then(response => {
        // console.log('response', response)
        if(response.data.status === 200){
          if (response.data.result.data.length === 0) {
            if(data.resetData){
              dispatch(setMessage({searchMessage: ['No opportunities have been published yet']}))
            } else {
              dispatch(setMessage({searchMessage: ['All Opportunities Has Been Loaded!']}))
            }
          } else {
            console.log(response.data.result)
            //send new data to store
            dispatch(setOpportunities({
              opportunities:response.data.result.data,
              newCount:response.data.result.count ? response.data.result.count : 0,
              pages:response.data.result.count,
              resetData: data.resetData
            }));
          }
          dispatch(setError({loading: false, error:null, redirect: null}));
        }else {
          if(response.data.data === 'User_is_not_registered3') {
            dispatch(setError({loading: false, error: 'You are not registered!', redirect: null}));
          }else {
            dispatch(setError({loading: false, error: 'We Have Error', redirect: null}));
          }
        }
      })
      .catch(err => {
        console.log(err);
        //handle show error
        dispatch(setError({loading: false, error: 'We Have Error', redirect: null}));
      });
  }
}
