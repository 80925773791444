import {combineReducers} from 'redux';
import authReducer from './auth';
import opportunitiesReducers from './opportunities';
import postsReducers from './posts';
import globalReducers from './global';
import messagesReducers from './messages';
import searchMembersReducer from './search';

export default combineReducers({
    auth: authReducer,
    opportunities: opportunitiesReducers,
    posts: postsReducers,
    global: globalReducers,
    messages: messagesReducers,
    search: searchMembersReducer,
});
