import * as actionTypes from '../../constants/actionTypes';
import { updateObject } from '../utility';
const initialState = {
    items: [],
    count: 0,
    pages: 0
};

const setSearchMembers = (state = initialState, action) => {
    if (action.payload.resetData) {
        return updateObject(state, {
            items: action.payload.items,
            count: action.payload.items.length,
            pages: action.payload.pages,
        });
    } else {
        action.payload.items.map(item => state.items.push(item))
        state.count = action.payload.items.length;
        state.pages = action.payload.items;
        return updateObject(state, state);
    }
};


const searchMembersReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.SET_SEARCH_MEMBERS: return setSearchMembers(state, action);
        default:
            return state;
    }
};

export default searchMembersReducer;
